import { APICompsSchema } from "@unit/apis";
import { addDays, addMonths } from "date-fns";
import { atom } from "jotai";
import { DateRange } from "rsuite/DateRangePicker";

import { InstagramGraphAPIType } from "@/types/FacebookGraphApi";

export const loadingAtom = atom<boolean>(false);
export const idTokenAtom = atom<string | undefined>(undefined);
export const authUserAtom = atom<APICompsSchema["StaffObject"] | null>(null);
export const selectStoreAtom = atom<APICompsSchema["FlatStoreObject"] | null>(null);
export const genresAtom = atom<APICompsSchema["FlatGenreObject"][] | null>(null);
export const areasAtom = atom<APICompsSchema["FlatAreaObject"][] | null>(null);
export const ticketFormChangedAtom = atom<"new" | "edit" | null>(null);
export const instagramAccountsForAuthAtom = atom<InstagramGraphAPIType[]>([]);

export const currentStoresTicketsAtom = atom<APICompsSchema["TicketObject"][] | null>(null);

export const selectedTicketsAtom = atom<APICompsSchema["TicketObject"][]>([]);
export const selectTicketObjForOfferAtom = atom<APICompsSchema["TicketObject"] | null>(null);

export const selectInfluencerTicketIdAtom = atom<{
  chatOpen: boolean;
  influencerTicketId: string;
}>({
  chatOpen: false,
  influencerTicketId: "",
});

export const pageTitleAtom = atom<string>("");
export const dateRangeAtom = atom<DateRange>([addDays(addMonths(new Date(), -1), -1), addDays(new Date(), -1)]);

export const CustomMUIDataTableStateAtom = atom<{
  offset: number;
  limit: number;
  sortBy: string;
  keyword?: string;
}>({ offset: 0, limit: 25, sortBy: "updatedAt-desc" });

export const CustomMUIDataTablePageAtom = atom<{
  page: number;
  rowsPerPage: number;
}>({ page: 0, rowsPerPage: 25 });
